<template>
    <ElementsDialog :active="expired">
        <div class="flex flex-col gap-5 relative overflow-hidden">
            <h2 class="text-2xl">Ihre Sitzung ist abgelaufen.</h2>
            <p>Bitte melden Sie sich erneut an.</p>
            <div>
                <ElementsButton :loading="loading" @click="signInAgain">Anmelden</ElementsButton>
            </div>
            <ElementsIcon size="custom" icon="product" class="absolute right-0 top-0.5 z-10 text-gray-300 w-32 h-32 -rotate-45" />
        </div>
    </ElementsDialog>
</template>
<script setup>
import { jwtDecode } from "jwt-decode";

const token = useStrapiToken()
const { setToken } = useStrapiAuth()
const { create } = useStrapi4()
const refreshToken = useRefreshToken()

const expires = computed( () => token.value ? new Date(jwtDecode(token.value)?.exp * 1000) : false );
const expired = ref(false)
const loading = ref(false)

const refreshJwtToken = async() => {
    let result = await create('token/refresh', { refreshToken: refreshToken.value })
    if( result?.jwt ) 
        setToken(result?.jwt)
    else
        expired.value = true
}


const checkSessionExpires = async() => {
    let currentDate = new Date();
    
    await refreshJwtToken()

    console.log('expiring at', expires.value, 'refreshing in ', Math.abs(expires.value - currentDate - 6000) )

    setTimeout(checkSessionExpires, Math.abs(expires.value - currentDate - 6000) );
}
if(token.value){
    setTimeout(checkSessionExpires, Math.abs(expires.value - (new Date()) - 6000) );
    console.log('session expires at ', expires.value)
}

const signInAgain = () => {
    loading.value = true
    window.location.reload()
}

</script>