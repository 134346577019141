<template>
    <div class="top-0 left-0 w-full z-50 dark:bg-gray-900 dark:bg-opacity-80" v-bind:class="{
            'sticky p-4' : route.meta.fullwidth === undefined || !route.meta.fullwidth,
            'fixed py-4' : route.meta.fullwidth !== undefined && route.meta.fullwidth,

            'bg-white bg-opacity-80 shadow-md' : route.meta.transparent === undefined || !route.meta.transparent,
            'bg-transparent dark:bg-transparent' : route.meta.transparent !== undefined && route.meta.transparent,
        }">
        <div class="flex flex-none px-4 sm:px-6 mx-auto max-w-7xl">
            <div class="flex basis-full">
                <div class="xl:-ml-8 flex items-center">
                    <ElementsHamburgerMenu @toggle="menuOpen = !menuOpen" :open="menuOpen" />
                </div>
                <div class="flex basis-1/2 items-center flex-1 lg:flex-none justify-start">
                    <NuxtLink to="/" class="flex gap-1.5 md:gap-2.5 overflow-auto">
                        <template v-if="isProducer">
                            <ElementsIcon v-if="!data?.data?.attributes?.organisations?.data?.length" icon="product" size="big" class="dark:text-white" />
                            <template v-else>
                                <DynamicData :getData="() => find('contracts', { filters: {
                                        $and: [
                                            { business: { id: { $eq: data?.data?.id } } },
                                            { archived: { $eq: false } }
                                        ]
                                    },
                                    populate: ['organisation.logo']})" dataKey="business-current-contracts"
                                    v-slot="{ data: contracts }">
                                <template v-for="c in contracts?.data" :key="c?.id">
                                    <ElementsImage :data="c?.attributes?.organisation?.data?.attributes?.logo?.data" :invertIfDark="c?.attributes?.organisation?.data?.attributes?.invertIfDark" />
                                </template>
                                <template v-if="contracts?.data?.length == 0">
                                    <template v-for="org in data?.data?.attributes?.organisations?.data" :key="org?.id">
                                            <ElementsImage :data="org?.attributes?.logo?.data" :invertIfDark="org?.attributes?.invertIfDark" />
                                    </template>
                                </template>
                                </DynamicData>

                            </template>
                        </template>
                        <template v-else-if="user?.organisation?.id">
                            <DynamicData :getData="() => findOne('organisations', user?.organisation?.id, { populate: ['logo']})" :dataKey="'org-logo-'+user?.organisation?.id"
                                 v-slot="{ data: orgData }">
                                 <ElementsImage :data="orgData?.data?.attributes?.logo?.data" :invertIfDark="orgData?.data?.attributes?.invertIfDark" />
                            </DynamicData>
                        </template>
                        <ElementsIcon v-else icon="product" size="big" class="dark:text-white" />
                    </NuxtLink>
                </div>

            </div>
            <div class="flex items-center justify-end lg:flex-auto h-18" v-bind:class="{
                'mr-8': route.meta.fullwidth
            }">
                <ThemeAccount />
            </div>
        </div>
        <LazyThemeFlyOut :open="menuOpen" @close="menuOpen = false" @help="emit('help')" />
    </div>
</template>
<script setup>
const route = useRoute()
const { find, findOne } = useStrapi4()
const user = useStrapiUser()
const backendUrl = useBackendUrl()
const emit = defineEmits(['help'])

const menuOpen = ref(false)

const isProducer = useIsProducer()

const { data, pending } = useLazyAsyncData(
    () => {
        return findOne('businesses', 'current')
        if(isProducer){
        }
        else {
            return useStrapiUser()
        }
    }
)

</script>