<template>
    <template v-if="data">
        <img v-if="data?.attributes?.formats?.thumbnail?.url"
            :src="backendUrl + data?.attributes?.formats?.thumbnail?.url" alt="Zürioberland"
            class="h-16 w-auto" v-bind:class="{ 'dark:invert': invertIfDark }" />
        <img v-else-if="data?.attributes?.url"
            :src="backendUrl + data?.attributes?.url" alt="Zürioberland"
            class="h-16 w-auto" v-bind:class="{ 'dark:invert': invertIfDark }" />
        <ElementsIcon v-else icon="product" size="big" class="dark:text-white" />
    </template>
</template>
<script setup>
defineProps({
    data: { default: {}, },
    invertIfDark: { type: Boolean, default: false, },
})

const backendUrl = useBackendUrl()
</script>